import Dropdown from "react-bootstrap/Dropdown";
import Login from "./Login";
import Image from "react-bootstrap/Image";
import Logo from "../Images/logo.png";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSignOut,
  faLifeRing,
  faShieldHalved,
  faPlus,
  faVoteYea,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import Cookies from "js-cookie";

const PageNav = () => {
  //const user = false;
  const currentPage = useLocation();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<any>({});
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("user_admin");
    setUserDetails({});
    setUserIsAdmin(false);
    navigate("/");
  };

  const getUserDetails = async () => {
    const accessToken = Cookies.get("access_token");
    const userAdmin = Cookies.get("user_admin");
    setUserIsAdmin(!userAdmin || userAdmin === "false" ? false : true);
    if (accessToken) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(`https://discord.com/api/users/@me`, {
        method: "GET",
        headers: headers,
      });
      const data = await response.json();
      setUserDetails(data);
    }
  };

  useEffect(() => {
    getUserDetails();
  });

  return (
    <Navbar expand="lg" className="bg-body-tertiary bg-dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={Logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Larry Games
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" defaultActiveKey="home">
            <Nav.Link
              key="home"
              href="/"
              active={currentPage.pathname === "/" ? true : false}
            >
              <FontAwesomeIcon icon={faHome} fixedWidth />
              Home
            </Nav.Link>
            {!userIsAdmin ? (
              <></>
            ) : (
              <Nav.Link
                key="skyfinger"
                href="/skyfinger"
                active={currentPage.pathname === "/skyfinger" ? true : false}
              >
                <FontAwesomeIcon icon={faHome} fixedWidth />
                Skyfinger
              </Nav.Link>
            )}
            <Nav.Link href="/terms">
              <FontAwesomeIcon icon={faShieldHalved} fixedWidth />
              Terms & Privacy
            </Nav.Link>
            <Nav.Link href="https://discord.gg/r9WZ4ywbEx">
              <FontAwesomeIcon icon={faLifeRing} fixedWidth />
              Support Server
            </Nav.Link>
            <Nav.Link href="https://discord.com/oauth2/authorize?client_id=1026495225759277146">
              <FontAwesomeIcon icon={faPlus} fixedWidth />
              Invite Larry
            </Nav.Link>
            <Nav.Link href="https://top.gg/bot/1026495225759277146/vote">
              <FontAwesomeIcon icon={faVoteYea} fixedWidth />
              Vote on Top.gg
            </Nav.Link>
          </Nav>
          <Navbar.Text>
            {!userDetails || !userDetails.id || !Cookies.get("access_token") ? (
              <Login />
            ) : (
              <Dropdown>
                <Dropdown.Toggle variant="secondary">
                  <Image
                    rounded
                    alt=""
                    src={
                      "https://cdn.discordapp.com/avatars/" +
                      userDetails.id +
                      "/" +
                      userDetails.avatar
                    }
                    width="30"
                    height="30"
                  />{" "}
                  {userDetails.username}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOut} fixedWidth />
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PageNav;
