const SkyfingerPage = () => {
  return (
    <>
      <h2>Skyfinger Admin</h2>
      <p>
        Download the <a href="../json/characters.json">characters.json</a>
      </p>
    </>
  );
};

export default SkyfingerPage;
