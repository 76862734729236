import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import isUserAdmin from "../Inc/isUserAdmin";

const Secure = ({ children }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserAdmin()) {
      navigate("/");
    }
  });

  return children;
};

export default Secure;
