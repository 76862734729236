import Cookies from "js-cookie";

const isUserAdmin = async () => {
  const accessToken = Cookies.get("access_token");
  if (!accessToken) return false;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  let response = await fetch(
    `https://discord.com/api/users/@me/guilds/1215799180908433448/member`,
    {
      method: "GET",
      headers: headers,
    }
  );
  let data = await response.json();

  if (
    !data ||
    !data.user ||
    !data.roles ||
    (data.user.id !== "765796161499824148" &&
      !data.roles.includes("1339025552140533834"))
  ) {
    return false;
  } else {
    Cookies.set("user_admin", "true");
    return true;
  }
};

export default isUserAdmin;
