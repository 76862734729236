import PageNav from "./Components/PageNav";
import { Route, Routes } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Secure from "./Components/Secure";
import TermsPage from "./Pages/Terms";
import SkyfingerPage from "./Pages/Skyfinger";
const Home = () => {
  return (
    <>
      <h2>What is Larry Games?</h2>
      <p>
        Host game nights easily with Larry Games, a group game bot with various
        unique games! Regular updates and improvements are being made.
      </p>
    </>
  );
};

const Games = () => {
  return <h2>Games</h2>;
};

function App() {
  return (
    <>
      <PageNav />
      <Container>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route index path="/games" element={<Games />} />
          <Route
            path="/skyfinger"
            element={
              <Secure>
                <SkyfingerPage />
              </Secure>
            }
          />
          <Route path="/terms" element={<TermsPage />} />
        </Routes>
      </Container>
    </>
  );
}

export default App;
